<template>
  <div class="modal fade " id="eventCalendarViewModal" tabindex="-1" role="dialog" aria-labelledby="eventCalendarViewModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Event Calendar Details</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <h4 class="text-center">{{ eventCalendar.name }}</h4>
          <div class="text-center">
            {{ eventCalendar.type }}
          </div>
          <div>
            <p>
              <strong>Date: </strong>
                {{ eventCalendar.date ?? 'Not Provided' }}
            </p>
            <p>
              <strong>Jurisdiction: </strong>
              <span class="text-uppercase">{{ eventCalendar.jurisdiction ?? 'Not Provided' }}</span>
            </p>
          </div>
          <div>
            <h6 class="text-center">Information</h6>
            <hr/>
            <p v-html="eventCalendar.information"></p>
          </div>
          <div>
            <h6 class="text-center">More Information</h6>
            <hr/>
            <p v-html="eventCalendar.more_information"></p>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "EventCalendarViewModal",
  props: ['eventCalendar'],
  computed: { }
}
</script>

<style scoped>

</style>
<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">EventCalendars/List</h4>
          <div>
            <router-link :to="can('event-calendar-create') ? {name:'appEventCalendarCreate'} : '#'">
              <span :title="can('event-calendar-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>Show Per Page</label>
                  <VueMultiselect v-model="selectedPagination" :options="paginationList" :close-on-select="true"
                                  label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>Type</label>
                  <VueMultiselect v-model="selectedType" class="" :options="typeList" :close-on-select="true" label="name"
                                  track-by="value" :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>Date From</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedFromDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>Date To</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedToDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :min-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <label>Jurisdiction</label>
                  <VueMultiselect v-model="selectedJurisdiction" class="" :options="JurisdictionOptions" :close-on-select="true"
                                  label="name" track-by="name" :show-labels="false" :allow-empty="false"/>
                </div>
                </div>

              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Search By Event</label>
                  <input v-model="getEventCalendarsParams.name" class="form-control search-admin-input-element" type="text"
                         placeholder="Search By Event Name">
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <label for="" style="visibility: hidden">Filter</label>
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyEventCalendarFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" style="width: 5%">SL</th>
                    <th class="position-relative" style="width: 25%">Date</th>
                    <th class="position-relative" style="width: 25%">Jurisdiction</th>
                    <th class="position-relative" style="width: 25%">Name</th>
                    <th class="position-relative" style="width: 15%">Type</th>
                    <th class="position-relative text-right" style="width: 5%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(eventCalendar, index) in eventCalendars" :key="index">
                    <td>
                      <span>{{ index + 1 }}</span>
                    </td>
                    <td>
                      <span>{{ eventCalendar.date }}</span>
                    </td>
                    <td>
                      <span>{{ eventCalendar.jurisdiction.toUpperCase() }}</span>
                    </td>
                    <td>
                      <span>{{ eventCalendar.name }}</span>
                    </td>

                    <td>
                      <span>{{ eventCalendar.type }}</span>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-end">
                          <button :title="can('event-calendar-view') ? 'View' : 'View Unauthenticated'"
                                  :disabled="!can('event-calendar-view')"
                                  @click="this.viewEventCalendarObj = eventCalendar"
                                  class="btn text-decoration-none cursor-pointer view-edit-delete-icon px-0"
                                  data-toggle="modal"
                                  data-target="#eventCalendarViewModal">
                            <i class="bx bx-show"></i>
                          </button>
                     </div>
                    </td>

                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyEventCalendarFilter"/>
              </div>
            </div>
          </div>
        </div>
        <event-calendar-view-modal :eventCalendar="viewEventCalendarObj"/>


      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import EventCalendarViewModal from "@/views/backEnd/eventCalendars/includes/EventCalendarView";


// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';
import {DatePicker} from "v-calendar";

export default {
  name: "EventCalendarList.vue",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ListPagination,
    VueMultiselect,
    DatePicker,
    EventCalendarViewModal,
  },
  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',
      viewEventCalendarObj: {},

      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedType: {
        value: '',
        name: 'Any'
      },
      selectedJurisdiction: {
        value: '',
        name: "Any",
      },

      getEventCalendarsParams: {
        name: '',
        date: [],
        type: '',
        jurisdiction: '',
        paginate: 1,
        order_by_id: 'DESC',
        pagination: '',
        page: '',

      },
      selectedFromDate: '',

      selectedToDate: '',

      getSettingsParams: {
        type: ['default'],
        key: [
          "default_pagination",
          "default_state",
        ],
      },

    };
  },

  watch: {
    selectedPagination(selectedPagination) {
      this.getEventCalendarsParams.pagination = selectedPagination.value;
    },
    selectedType(selectedType) {
      this.getEventCalendarsParams.type = selectedType.value;
    },
    selectedFromDate(selectedFromDate) {
      this.getEventCalendarsParams.date[0] = selectedFromDate;
    },

    selectedToDate(selectedToDate) {
      this.getEventCalendarsParams.date[1] = selectedToDate;
    },
    selectedJurisdiction(selectedJurisdiction) {
      console.log(selectedJurisdiction.value)
      this.getEventCalendarsParams.jurisdiction = selectedJurisdiction.value;
    },

  },

  computed: {
    ...mapGetters({

      eventCalendars: 'appEventCalendars/eventCalendars',
      paginateLinks: 'appEventCalendars/paginateLinks',
    }),

    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

    JurisdictionOptions() {
      return [{value: null, name: "Any"}, ...this.$store.getters["appSettings/settingDefaultState"].value];
    },
    typeList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Public Holiday'
        },
      ]
    },

  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getEventCalendars: 'appEventCalendars/getEventCalendars',
      resetEventCalendars: 'appEventCalendars/resetEventCalendars',
    }),

    currentDate() {
      let date = new Date();
      return date.toISOString().split("T")[0];
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getEventCalendarList() {
      await this.getEventCalendars(this.getEventCalendarsParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },

    async applyEventCalendarFilter(pageNumber) {
      this.loader(true);
      this.getEventCalendarsParams.page = pageNumber;
      await this.getEventCalendarList();
      this.loader(false);

      if (this.selectedFromDate && this.selectedToDate) {
        this.getEventCalendarsParams.date = [this.selectedFromDate, this.selectedToDate]
      }

      this.getEventCalendarsParams.jurisdiction = this.selectedJurisdiction.value !== null ? this.selectedJurisdiction.value : "";
    },


  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    this.getEventCalendarsParams.date[0] = this.currentDate();
    this.selectedFromDate = this.currentDate();

    await this.getEventCalendarList();

    this.loader(false);

  },

  async beforeUnmount() {
    await this.resetEventCalendars();
  },

}
</script>



<style scoped>

.view-edit-delete-icon {
  color: #B3C0CE;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5A8DEE;
}

.search-admin-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
</style>